import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const liff = window.liff;

export class StoresettingMaxHour extends Component {
    static displayName = StoresettingMaxHour.name;

    constructor(props) {
        super(props);

        this.state = {
            roomid: "0",
            storeName: "",
            storeStatus: "",
            room1Status: "",
            room2Status: "",
            maxhr1: "",
            maxhr2: "",
            storeId: "",
            roomlist: []
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    onInputchange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    async onSubmitForm() {
        //Godd to go

        // POST request using fetch inside useEffect React hook                    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    storeId: this.state.storeId,
                    room1maxhr: this.state.maxhr1,
                    room2maxhr: this.state.maxhr2
                }
            )
        };

        const response = await fetch('/api/partnerroommaxhr', requestOptions);
        const data = await response.json();

        if (data.responsecode === "OK") {
            //Update filter
            this.props.history.push('/Dashboard')
        }
        else {
            this.props.history.push({
                pathname: "/Mistake",
                state: {
                    ErrorTxt: data.responsemessage
                }
            });
        }
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
            //let LineuserPicture = "";            

        } else {
            liff.init({
                liffId: '1661485377-z2p58Y1Z'
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start

                    //check Partners exists or not - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/partnercheck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "Approve") {
                                //Partners exists and already approve-> Show This page
                                this.setState({
                                    userLineID: LineuserId,
                                    storeName: json.storeName,
                                    storeStatus: json.storeStatus,
                                    room1Status: json.room1Status,
                                    room2Status: json.room2Status,
                                    room1maxhr: json.room1maxhr,
                                    room2maxhr: json.room2maxhr,
                                    storeId: json.storeId
                                });

                            }
                            else {
                                //Partners not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Partners exists or not - end

                    this.setState({
                        jpage: "done"
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            var storeName = this.state.storeName;
            var storeStatus = this.state.storeStatus;
            var room1Status = this.state.room1Status;
            var room2Status = this.state.room2Status;

            var roommxhr1 = this.state.room1maxhr;
            var roommxhr2 = this.state.room2maxhr;

            var cssStatus02 = "d-none";
            //if (this.state.room2Status === 'Active') {
            //    cssStatus02 = "row p-3 pb-0 justify-content-center mt-4";
            //}
            //else {
            //    cssStatus02 = "d-none";
            //}
        }

        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/stb_partner.png")} alt="SBUX Partner Portal" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-4">
                                <h2 className="h5 text-center pt-0 mb-0 fontsodo-bold">Edit Max Hour per Booking</h2>
                                <div className="text-center detail-confirmed mt-2">
                                    <p><span className="fs-5 fontsodo-bold text-green">{storeName}</span><br />
                                        <span className="fs-6 fontsodo-semibold text-black pe-4">Status</span> <span className="text-starbucks-green">{storeStatus}</span></p>
                                </div>
                                <div className="row p-3 pb-0 justify-content-center">
                                    <div className="col-3 text-center">
                                        <label className="fontsodo-bold text-black pb-2 d-none">Room 1</label>
                                        <input type="number" min="1" max="10" className="form-control" required name="maxhr1" defaultValue={roommxhr1} onChange={this.onInputchange} />
                                    </div>
                                </div>
                                <div className={cssStatus02}>
                                    <div className="col-3 text-center">
                                        <label className="fontsodo-bold text-black pb-2">Room 2</label>
                                        <input type="number" min="1" max="4" className="form-control" required name="maxhr2" defaultValue={roommxhr2} onChange={this.onInputchange} />
                                    </div>
                                </div>
                                <div className="row p-3 pb-0 fontsodo-bold">
                                    <div className="col-12 text-center"><p className="fontsodo-semibold mb-0">*Default 4 Hours per Booking</p></div>
                                </div>
                            </div>
                            <div className="box-footer-button">
                                <div className="row text-center mt-3 mb-0 justify-content-center">
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <Link to="/" className="btn btn-lg mb-0 btn-register-3">Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <input type="button" className="btn btn-lg mb-0 btn-register-2" defaultValue="Apply" onClick={this.onSubmitForm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>
        );
    }
}
