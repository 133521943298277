import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const liff = window.liff;

export class StoresettingTempcloseAddErr02 extends Component {
    static displayName = StoresettingTempcloseAddErr02.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCssButton: "btn btn-lg mb-0 btn-register-2",
            storeName: this.props.location.state.storeName,
            roomName: this.props.location.state.roomName,
            susDateTxt: this.props.location.state.susDateTxt,
            susDateHrStartTxt: this.props.location.state.susDateHrStartTxt,
            susDateHrEndTxt: this.props.location.state.susDateHrEndTxt,
            roomid: this.props.location.state.roomid,
            jpage: "",
            bookinglist: []
        };
    }   

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
            //let LineuserPicture = "";            
        } else {
            liff.init({
                liffId: '1661485377-z2p58Y1Z'
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start

                    //check Partners exists or not - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/partnercheck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "Approve") {
                                //Partners exists and already approve-> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Partners not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Partners exists or not - end

                    //retrive booking list in this period - start
                    const requestOptions2 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId,
                                SuspendedDate: this.state.susDateTxt,
                                SuspendedTimeStart: this.state.susDateHrStartTxt,
                                SuspendedTimeEnd: this.state.susDateHrEndTxt,
                                RoomID: this.state.roomid
                            }
                        )
                    };

                    await fetch('/api/bookinglistaddtempcloseerror', requestOptions2)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                bookinglist: json
                            });
                        });
                    //retrive booking list in this period  - end

                    this.setState({
                        jpage: 'done'
                    });
                    //action - end
                }
            });
        }
    }

    render() {       
        if (this.state.jpage === "done") {
            //upcoming booking today - start
            var bookinglist = [];
            var cssbutton01 = "";
            for (let i = 0; i < this.state.bookinglist.length; i++) {
                switch (this.state.bookinglist[i].status) {
                    case "Confirmed": cssbutton01 = "btn btn-sm mt-2 mb-0 btn-register-2"; break;
                    case "Check-in": cssbutton01 = "btn btn-sm mt-2 mb-0 btn-register-4"; break;
                    case "Cancel": cssbutton01 = "btn btn-sm mt-2 mb-0 btn-register-cancelled"; break;
                    case "Completed": cssbutton01 = "btn btn-sm mt-2 mb-0 btn-register-completed"; break;
                    default: cssbutton01 = "btn btn-sm mt-2 mb-0 btn-register-completed"; break;
                }

                bookinglist.push(
                    <div className="card card-room-list mb-1" key={i}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-8"><div className="head-room-name">{this.state.bookinglist[i].storeName}</div></div>
                                <div className="col-4 text-end lh-1"><span className="booking-no">Booking No.</span><br /><span className="booking-number">{this.state.bookinglist[i].bookingRunId}</span></div>
                            </div>
                            <div className="row row-room-detail">
                                <div className="col-8 p-0 ps-2">
                                    <div><span className="room-detail"><img src={require("./media/icon-meeting.png")} alt="Room" className="ms-1" /> {this.state.bookinglist[i].roomName} for {this.state.bookinglist[i].roomMaxGuest} people</span></div>
                                    <div><span className="room-text-detail"><img src={require("./media/Icon-feather-calendar.png")} alt="Date" className="ms-1" />{this.state.bookinglist[i].dateStartTxt}</span> <img src={require("./media/Icon-feather-clock.png")} alt="Time" className="ms-2" /> <span className="room-text-detail">{this.state.bookinglist[i].timeStart} - {this.state.bookinglist[i].timeEnd}</span></div>
                                </div>
                                <div className="col-4 text-end align-self-end">
                                    <Link to={{
                                        pathname: "/BookingDetail",
                                        state: {
                                            bookingrunid: this.state.bookinglist[i].bookingRunId
                                        }
                                    }} className={cssbutton01} >
                                        {this.state.bookinglist[i].status}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            //upcoming booking today - end

        }
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/stb_partner.png")} alt="SBUX Partner Portal" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-4">
                                <h2 className="h4 text-center pt-0 mb-0 fontsodo-bold">Temporary Store Closure</h2>
                                <div className="row p-3 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Store</p></div>
                                    <div className="col-10"><p className="fontsodo-semibold text-green mb-0">{this.state.storeName}</p></div>
                                </div>
                                <div className="row p-3 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Room</p></div>
                                    <div className="col-10"><p className="fontsodo-semibold text-green mb-0">{this.state.roomName}</p></div>
                                </div>
                                <div className="row p-3 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Date</p></div>
                                    <div className="col-10"><p className="fontsodo-semibold mb-0"><img src={require("./media/Icon-feather-calendar.png")} alt="Date" className="ms-0 me-2" /> {this.state.susDateTxt}</p></div>
                                </div>
                                <div className="row p-3 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Time</p></div>
                                    <div className="col-10"><p className="fontsodo-semibold mb-0"><img src={require("./media/Icon-feather-clock.png")} alt="Time" className="ms-0 me-2" /> {this.state.susDateHrStartTxt} - {this.state.susDateHrEndTxt}</p></div>
                                </div>
                                <p className="mt-3 text-center lh-sm fs-6 fontkanit"><span className="text-danger">มีการจองอยู่ในวันเวลาที่ท่านเลือก</span><br />
                                    รายการจองทั้งหมด ภายในวันที่ท่านเลือก</p>

                                <div className="row p-2 pt-0 row-card-room-list-2">
                                    <div className="col-12 mb-2">
                                        {bookinglist}
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer-button position-relative">
                                <div className="row text-center mt-3 mb-0 justify-content-center">
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <Link to="/StoresettingTempcloseAdd" className="btn btn-lg mb-0 btn-register-3">Back</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>
        );
    }
}