import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const liff = window.liff;

export class StoresettingTempcloseAdd extends Component {
    static displayName = StoresettingTempcloseAdd.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCssButton: "btn btn-lg mb-0 btn-register-2",
            roomlist: [],
            roomid: "",
            susdate: "",
            period: "All Day",
            sustimestart: "",
            sustimeend: "",
            css01: "row p-3 pb-0 d-none",
            check01: "checked",
            check02: ""
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.closeModal = this.closeModal.bind(this);
        this.BoxTimeShow = this.BoxTimeShow.bind(this);
        this.BoxTimeHide = this.BoxTimeHide.bind(this);
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none"
        });
    }

    BoxTimeShow() {
        this.setState({
            css01: "row p-3 pb-0 ",
            check01: "",
            check02: "checked"
        });
    }

    BoxTimeHide() {
        this.setState({
            css01: "row p-3 pb-0 d-none",
            check01: "checked",
            check02: ""
        });
    }

    onInputchange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    async onSubmitForm() {
        let isthisvalid = "yes";

        if (!this.state.susdate) {
            this.setState({
                ModelCssinner01: ""
            });

            isthisvalid = "no";
        }
        else {
            //can not select date < yesterday
            const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
            var date = new Date(this.state.susdate);
            if (date < yesterday) {
                this.setState({
                    ModelCssinner01: ""
                });

                isthisvalid = "no";
            }
        }

        if (!this.state.period) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        }
        else {
            if (this.state.period === "Period") {
                const date0 = new Date().setHours(this.state.sustimestart.slice(0, 2), this.state.sustimestart.slice(-2));
                const date1 = new Date().setHours(this.state.sustimeend.slice(0, 2), this.state.sustimeend.slice(-2));
                var diffMins = Math.round((date1 - date0) / 60000);
                if (diffMins <= 0) {
                    //time end > time start
                    this.setState({
                        ModelCssinner02: ""
                    });

                    isthisvalid = "no";
                }
            }
        }

        if (isthisvalid === "no") {
            this.setState({
                ModelCss01: "popup"
            });
        }
        else {
            //Godd to go            
            //disable submit button
            this.setState({
                ModelCssButton: "btn btn-lg mb-0 btn-register-2-dis"
            });

            this.props.history.push({
                pathname: "/StoresettingTempcloseAddConfirm",
                state: {
                    roomid: this.state.roomid,
                    susdate: this.state.susdate,
                    sustimestart: this.state.sustimestart,
                    sustimeend: this.state.sustimeend,
                    period: this.state.period
                }
            });
        }
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
            //let LineuserPicture = "";            
        } else {
            liff.init({
                liffId: '1661485377-z2p58Y1Z'
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Partners exists or not - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/partnercheck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "Approve") {
                                //Partners exists and already approve-> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Partners not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Partners exists or not - end

                    //retrive room list - start
                    const requestOptions2 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };
                    await fetch('/api/partnerroomlist', requestOptions2)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                roomlist: json
                            });
                        });

                    if (this.state.roomlist.length >= 1) {
                        this.setState({
                            roomid: this.state.roomlist[1].roomId,
                            jpage: 'done'
                        });
                    }
                    //retrive room list - end
                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            //Min Max Date select - Start
            var mindate = "";
            var maxdate = "";

            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1; //January is 0
            var mm2 = today.getMonth() + 2; //Next month
            var yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd
            }
            if (mm < 10) {
                mm = '0' + mm
            }
            if (mm2 < 10) {
                mm2 = '0' + mm2
            }

            mindate = yyyy + '-' + mm + '-' + dd;
            maxdate = yyyy + '-' + mm2 + '-' + dd;
            //Min Max Date select - End

            //Dropdown room list - start
            var roomlist = [];
            for (let i = 0; i < this.state.roomlist.length; i++) {
                if (this.state.roomlist[i].roomId !== "0") {
                    roomlist.push(
                        <option key={i} value={this.state.roomlist[i].roomId}>[{this.state.roomlist[i].storeName}]-{this.state.roomlist[i].roomName}</option>
                    )
                }
            }
            //Dropdown room list - end

            //check period - start
            var chk01 = this.state.check01;
            var chk02 = this.state.check02;
            //check period - end
        }
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/stb_partner.png")} alt="SBUX Partner Portal" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-4">
                                <h2 className="h5 text-center pt-0 mb-0 fontsodo-bold">Temporary Store Closure</h2>
                                <div className="row p-3 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Room</p></div>
                                    <div className="col-10">
                                        <p className="fontsodo-semibold mb-0">
                                            <select className="form-control-select-popup" required name="roomid" defaultValue={this.state.roomid} onChange={this.onInputchange}>
                                                {roomlist}
                                            </select>
                                        </p>
                                    </div>
                                </div>
                                <div className="row p-3 pt-1 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Date</p></div>
                                    <div className="col-10">
                                        <div className="fontsodo-semibold mb-0">
                                            <div className="row">
                                                <div className="col-1 p-0"><img src={require("./media/Icon-feather-calendar.png")} alt="Date" className="ms-2" /></div>
                                                <div className="col-8"><input type="date" className="form-control form-control-sm input-datetime" style={{ padding: '7px 20px' }} required name="susdate" defaultValue={this.state.susdate} onChange={this.onInputchange} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row p-3 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Select</p></div>
                                    <div className="col-10">
                                        <p className="fontsodo-semibold mb-0">
                                            <label>
                                                <input type="radio" name="period" required id="period1" value="All Day" onChange={this.onInputchange} checked={chk01} onClick={this.BoxTimeHide} />
                                                All Day</label>
                                            <label className="ps-3">
                                                <input type="radio" name="period" required id="period2" value="Period" onChange={this.onInputchange} checked={chk02} onClick={this.BoxTimeShow} />
                                                Period</label>
                                        </p>
                                    </div>
                                </div>
                                <div className={this.state.css01} >
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Time</p></div>
                                    <div className="col-10">
                                        <div className="fontsodo-semibold mb-0">
                                            <div className="row">
                                                <div className="col-1 p-0"><img src={require("./media/Icon-feather-clock.png")} alt="Date" className="ms-2" /></div>
                                                <div className="col-4 p-0 ps-2">
                                                    <select className="form-control" required name="sustimestart" id="sustimestart" value={this.state.sustimestart} onChange={this.onInputchange}>
                                                        <option value="" disabled></option>
                                                        <option value="00:00">00:00</option>
                                                        <option value="00:30">00:30</option>
                                                        <option value="01:00">01:00</option>
                                                        <option value="01:30">01:30</option>
                                                        <option value="02:00">02:00</option>
                                                        <option value="02:30">02:30</option>
                                                        <option value="03:00">03:00</option>
                                                        <option value="03:30">03:30</option>
                                                        <option value="04:00">04:00</option>
                                                        <option value="04:30">04:30</option>
                                                        <option value="05:00">05:00</option>
                                                        <option value="05:30">05:30</option>
                                                        <option value="06:00">06:00</option>
                                                        <option value="06:30">06:30</option>
                                                        <option value="07:00">07:00</option>
                                                        <option value="07:30">07:30</option>
                                                        <option value="08:00">08:00</option>
                                                        <option value="08:30">08:30</option>
                                                        <option value="09:00">09:00</option>
                                                        <option value="09:30">09:30</option>
                                                        <option value="10:00">10:00</option>
                                                        <option value="10:30">10:30</option>
                                                        <option value="11:00">11:00</option>
                                                        <option value="11:30">11:30</option>
                                                        <option value="12:00">12:00</option>
                                                        <option value="12:30">12:30</option>
                                                        <option value="13:00">13:00</option>
                                                        <option value="13:30">13:30</option>
                                                        <option value="14:00">14:00</option>
                                                        <option value="14:30">14:30</option>
                                                        <option value="15:00">15:00</option>
                                                        <option value="15:30">15:30</option>
                                                        <option value="16:00">16:00</option>
                                                        <option value="16:30">16:30</option>
                                                        <option value="17:00">17:00</option>
                                                        <option value="17:30">17:30</option>
                                                        <option value="18:00">18:00</option>
                                                        <option value="18:30">18:30</option>
                                                        <option value="19:00">19:00</option>
                                                        <option value="19:30">19:30</option>
                                                        <option value="20:00">20:00</option>
                                                        <option value="20:30">20:30</option>
                                                        <option value="21:00">21:00</option>
                                                        <option value="21:30">21:30</option>
                                                        <option value="22:00">22:00</option>
                                                        <option value="22:30">22:30</option>
                                                        <option value="23:00">23:00</option>
                                                        <option value="23:30">23:30</option>
                                                        <option value="23:59">23:59</option>
                                                    </select>
                                                </div>
                                                <div className="col-1"> - </div>
                                                <div className="col-4 p-0 ps-2">
                                                    <select className="form-control" required name="sustimeend" id="sustimeend" value={this.state.sustimeend} onChange={this.onInputchange}>
                                                        <option value="" disabled></option>
                                                        <option value="00:00">00:00</option>
                                                        <option value="00:30">00:30</option>
                                                        <option value="01:00">01:00</option>
                                                        <option value="01:30">01:30</option>
                                                        <option value="02:00">02:00</option>
                                                        <option value="02:30">02:30</option>
                                                        <option value="03:00">03:00</option>
                                                        <option value="03:30">03:30</option>
                                                        <option value="04:00">04:00</option>
                                                        <option value="04:30">04:30</option>
                                                        <option value="05:00">05:00</option>
                                                        <option value="05:30">05:30</option>
                                                        <option value="06:00">06:00</option>
                                                        <option value="06:30">06:30</option>
                                                        <option value="07:00">07:00</option>
                                                        <option value="07:30">07:30</option>
                                                        <option value="08:00">08:00</option>
                                                        <option value="08:30">08:30</option>
                                                        <option value="09:00">09:00</option>
                                                        <option value="09:30">09:30</option>
                                                        <option value="10:00">10:00</option>
                                                        <option value="10:30">10:30</option>
                                                        <option value="11:00">11:00</option>
                                                        <option value="11:30">11:30</option>
                                                        <option value="12:00">12:00</option>
                                                        <option value="12:30">12:30</option>
                                                        <option value="13:00">13:00</option>
                                                        <option value="13:30">13:30</option>
                                                        <option value="14:00">14:00</option>
                                                        <option value="14:30">14:30</option>
                                                        <option value="15:00">15:00</option>
                                                        <option value="15:30">15:30</option>
                                                        <option value="16:00">16:00</option>
                                                        <option value="16:30">16:30</option>
                                                        <option value="17:00">17:00</option>
                                                        <option value="17:30">17:30</option>
                                                        <option value="18:00">18:00</option>
                                                        <option value="18:30">18:30</option>
                                                        <option value="19:00">19:00</option>
                                                        <option value="19:30">19:30</option>
                                                        <option value="20:00">20:00</option>
                                                        <option value="20:30">20:30</option>
                                                        <option value="21:00">21:00</option>
                                                        <option value="21:30">21:30</option>
                                                        <option value="22:00">22:00</option>
                                                        <option value="22:30">22:30</option>
                                                        <option value="23:00">23:00</option>
                                                        <option value="23:30">23:30</option>
                                                        <option value="23:59">23:59</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer-button position-relative">
                                <div className="row text-center mt-5 mb-0 justify-content-center">
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <Link to="/" className="btn btn-lg mb-0 btn-register-3">Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <input type="button" className={this.state.ModelCssButton} onClick={this.onSubmitForm} defaultValue="Apply" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                {/* Custom Pop up */}
                                <div id="popup1" className={this.state.ModelCss01}>
                                    <Link to="#" onClick={this.closeModal} className="close-popup">×</Link>
                                    <div className="text-center">
                                        <strong>กรุณากรอกข้อมูลให้ถูกต้อง</strong><br />
                                        <strong>และครบถ้วน</strong>
                                    </div>
                                    <ul className="text-start">
                                        <li className={this.state.ModelCssinner01}>Date</li>
                                        <li className={this.state.ModelCssinner02}>Time</li>
                                    </ul>
                                    <div className="text-center mt-3 mb-2">
                                        <div onClick={this.closeModal} className="btn btn-lg mb-0 btn-register-2">ตกลง</div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>
        );
    }
}