import React, { Component } from 'react';

//liffId: '1661485377-z2p58Y1Z'

const liff = window.liff;

export class HomeNotAllow extends Component {
    static displayName = HomeNotAllow.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide"
        };
    }
    
    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "x1";            
        } else {
            liff.init({
                liffId: '1661485377-z2p58Y1Z'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    this.setState({
                        userLineID: LineuserId
                    });
                    //action - end
                }
            });
        }
    }

  render () {
    return (
        <div className="form-signin shadow bg-home position-relative">
            <div className="head-nav p-0">
                <img src={require("./media/stb_partner.png")} alt="SBUX Partner Portal" className="img-fluid mt-0 mb-0" />
            </div>
            <div className="form-enter pt-0 mt-5">
                <form id="myform">
                    <h2 className="h4 text-center pt-4 mb-0 fontsodo-bold text-danger">Store ID และ Store Tel.</h2>
                    <h3 className="h5 text-center mb-3 fontsodo-semibold">ที่ท่านกรอกข้อมูลเข้ามามีการลงทะเบียนในระบบเรียบร้อยแล้ว</h3>
                    <fieldset className="fieldset-form pt-1">
                        <div className="row text-center mt-3 mb-0 justify-content-center">
                            <div className="col-6 p-0">
                                <div className="d-grid">
                                    <input type="button" className="btn btn-lg mt-2 mb-0 btn-register-2" onClick={this.closeLIFF} defaultValue="Close" />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>

    );
  }
}
