import React, { Component } from 'react';

export class NotInLine extends Component {
    static displayName = NotInLine.name;

    render() {
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/stb_partner.png")} alt="SBUX Partner Portal" className="img-fluid mt-0 mb-0" />
                </div>
                <div className="form-enter mt-0">
                    <div className="text-center">
                        <br /><br /><br /><br /><br />
                        <h3 className="pt-5 mt-5">Starbucks Community Room ได้ผ่านเบราว์เซอร์ภายในแอปพลิเคชัน LINE เท่านั้น</h3>
                   </div>
                </div>
            </div>

        );
    }
}