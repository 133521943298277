import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const liff = window.liff;

export class BookingDetail extends Component {
    static displayName = BookingDetail.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCss02: "popuphide",
            jpage: "",
            bookinglist: "",
            bookingrunid: this.props.location.state.bookingrunid,
            bookingcancelremark: "ไม่สามารถมาตามเวลาที่จองได้"
        };

        this.onInputchange = this.onInputchange.bind(this);

        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.onSubmitFormConfirm = this.onSubmitFormConfirm.bind(this);
        this.onSubmitFormCancel = this.onSubmitFormCancel.bind(this);
        this.onSubmitFormCancelConfirm = this.onSubmitFormCancelConfirm.bind(this);

        this.closeModal = this.closeModal.bind(this);
        this.closeModal2 = this.closeModal2.bind(this);
    }

    onInputchange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCss02: "popuphide"
        });
    }

    closeModal2() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCss02: "popuphide"
        });
    }

    async onSubmitForm() {
        // show confirm popup
        this.setState({
            ModelCss01: "popup"
        });
    }

    async onSubmitFormCancel() {
        // show confirm cancel popup
        this.setState({
            ModelCss02: "popup"
        });
    }

    async onSubmitFormConfirm() {
        //Do Check-in - start
        const requestOptions2 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    UserId: this.state.userLineID,
                    bookingrunid: this.state.bookingrunid
                }
            )
        };

        await fetch('/api/bookingcheckin', requestOptions2)
            .then(response => response.json())
            .then(json => {
                if (json.responsecode === "OK") {
                    this.props.history.push({
                        pathname: "/BookingCheckIn",
                        state: {
                            bookingrunid: this.state.bookinglist.bookingRunId,
                            selectedstore: this.state.bookinglist.storeName,
                            selectroom: this.state.bookinglist.roomName,
                            selectroommaxguest: this.state.bookinglist.roomMaxGuest,
                            selectdate: this.state.bookinglist.dateStartTxt,
                            timestart: this.state.bookinglist.timeStart,
                            timeend: this.state.bookinglist.timeEnd,
                            fullname: this.state.bookinglist.firstname,
                            mobile: this.state.bookinglist.mobile,
                            thisroomstoreaddr: this.state.bookinglist.googleMaps,
                            thisroomstoremobile: this.state.bookinglist.storeMobile
                        }
                    });
                }
                else {
                    this.props.history.push({
                        pathname: "/Mistake",
                        state: {
                            ErrorTxt: json.responsemessage
                        }
                    });
                }
            });
        //Do Check-in - end
    }

    async onSubmitFormCancelConfirm() {
        //Do Cancel - start
        const requestOptions2 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    UserId: this.state.userLineID,
                    bookingrunid: this.state.bookingrunid,
                    bookingcancelremark: this.state.bookingcancelremark
                }
            )
        };

        await fetch('/api/bookingcancel', requestOptions2)
            .then(response => response.json())
            .then(json => {
                if (json.responsecode === "OK") {
                    this.props.history.push({
                        pathname: "/BookingCancel",
                        state: {
                            bookingrunid: this.state.bookinglist.bookingRunId,
                            selectedstore: this.state.bookinglist.storeName,
                            selectroom: this.state.bookinglist.roomName,
                            selectroommaxguest: this.state.bookinglist.roomMaxGuest,
                            selectdate: this.state.bookinglist.dateStartTxt,
                            timestart: this.state.bookinglist.timeStart,
                            timeend: this.state.bookinglist.timeEnd,
                            fullname: this.state.bookinglist.firstname,
                            mobile: this.state.bookinglist.mobile,
                            thisroomstoreaddr: this.state.bookinglist.googleMaps,
                            thisroomstoremobile: this.state.bookinglist.storeMobile,
                            bookingrefno: this.state.bookinglist.BookingRefNo
                        }
                    });
                }
                else {
                    this.props.history.push({
                        pathname: "/Mistake",
                        state: {
                            ErrorTxt: json.responsemessage
                        }
                    });
                }
            });
        //Do Cancel - end
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
            //let LineuserPicture = "";            
        } else {
            liff.init({
                liffId: '1661485377-z2p58Y1Z'
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Partners exists or not - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/partnercheck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "Approve") {
                                //Partners exists and already approve-> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Partners not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Partners exists or not - end

                    //retrive booking by ID - start
                    const requestOptions2 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                bookingrunid: this.state.bookingrunid
                            }
                        )
                    };

                    await fetch('/api/bookingbyid', requestOptions2)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                bookinglist: json
                            });
                        });
                    //retrive booking by ID  - end

                    this.setState({
                        jpage: 'done'
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            var bookingrunid = this.state.bookinglist.bookingRunId;
            var bookingstatus = this.state.bookinglist.status;
            var selectedstore = this.state.bookinglist.storeName;
            var selectroom = this.state.bookinglist.roomName;
            var selectroommaxguest = this.state.bookinglist.roomMaxGuest;
            var selectdate = this.state.bookinglist.dateStartTxt;
            var timestart = this.state.bookinglist.timeStart;
            var timeend = this.state.bookinglist.timeEnd;
            var fullname = this.state.bookinglist.firstname;
            var mobile = this.state.bookinglist.mobile;
            var thisroomstoreaddr = this.state.bookinglist.googleMaps;
            var thisroomstoremobile = this.state.bookinglist.storeMobile;
            var thisroomstoremobilelink = "tel:" + this.state.bookinglist.storeMobile;
            var bookingrefno = this.state.bookinglist.bookingRefNo;
            var cancheckin = this.state.bookinglist.canCheckIn;

            var cssButton01 = "btn btn-lg mt-2 mb-0 btn-register-2 d-none";
            var cssButton02 = "btn btn-lg mt-2 mb-0 btn-register-cancelled d-none";

            //canCheckIn
            if (cancheckin === "No") {
                cssButton01 = "btn btn-lg mt-2 mb-0 btn-register-2 d-none";
                switch (bookingstatus) {
                    case "Confirmed":
                        cssButton02 = "btn btn-lg mt-2 mb-0 btn-register-cancelled";
                        break;
                    default:
                        cssButton02 = "btn btn-lg mt-2 mb-0 btn-register-cancelled d-none";
                        break;
                }
            }
            else {
                switch (bookingstatus) {
                    case "Confirmed":
                        cssButton01 = "btn btn-lg mt-2 mb-0 btn-register-2";
                        cssButton02 = "btn btn-lg mt-2 mb-0 btn-register-cancelled";
                        break;
                    default:
                        cssButton01 = "btn btn-lg mt-2 mb-0 btn-register-2 d-none";
                        cssButton02 = "btn btn-lg mt-2 mb-0 btn-register-cancelled d-none";
                        break;
                }
            }
        }
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/stb_partner.png")} alt="SBUX Partner Portal" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-3">
                                <h2 className="h4 text-center pt-4 mb-0 fontsodo-bold">Booking Status</h2>
                                <h3 className="h5 text-center mb-3 fontkanit fw-normal">สถานะการจอง</h3>
                                <div className="text-center detail-confirmed">
                                    <p><span className="fs-6 fontsodo-semibold">Your booking is {bookingstatus} at</span><br />
                                        <span className="fs-5 fontsodo-bold text-green">{selectedstore}</span><br />
                                        <span className="fs-6 fontsodo-semibold">Tel. <Link to={{ pathname: thisroomstoremobilelink }} target="_blank">{thisroomstoremobile}</Link></span></p>
                                    <p className="fs-6 fontsodo-semibold">Booking No. <span className="fs-5 fontsodo-bold text-green">{bookingrefno}</span></p>
                                    <div className="row justify-content-center">
                                        <div className="col-8">
                                            <p className="text-start lh-lg fs-6 fontsodo-semibold">
                                                <img src={require("./media/Icon-feather-calendar.png")} alt="Date" className="ms-1 me-2" /> {selectdate} <br />
                                                <img src={require("./media/icon-meeting.png")} alt="Room" className="ms-1 me-2" /> {selectroom} for {selectroommaxguest} people <br />
                                                <img src={require("./media/Icon-feather-clock.png")} alt="Time" className="ms-1 me-2" /> {timestart} to {timeend}</p>
                                            <p className="text-start lh-lg fs-6 fontsodo-semibold">
                                                <img src={require("./media/icon-user.png")} alt="Time" className="ms-1 me-2" />  <span className="text-starbucks-green fontsodo-semibold">{fullname}</span><br />
                                                <img src={require("./media/Icon feather-phone.png")} alt="Time" className="ms-1 me-2" /> <span className="fontsodo-semibold text-black">{mobile}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer-button position-relative">
                                <div className="row text-center mt-3 mb-0 justify-content-center">
                                    <div className="col-8 p-0">
                                        <div className="d-grid">
                                            <input type="button" className={cssButton01} onClick={this.onSubmitForm} defaultValue="Check-in" />
                                            <input type="button" className={cssButton02} onClick={this.onSubmitFormCancel} defaultValue="Cancel Booking" />
                                        </div>
                                    </div>
                                    <div className="col-6 p-0">
                                        <div className="d-grid">
                                            <Link to="/" className="btn btn-lg mt-4 mb-0 btn-register-3">Back</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                {/* Custom Pop up1 */}
                                <div id="popup1" className={this.state.ModelCss01}>
                                    <Link to="#" onClick={this.closeModal} className="close-popup">×</Link>
                                    <h5 className="text-center mt-3 mb-0 fontsodo-bold">Are you sure?</h5>
                                    <p className="text-center fontkanit"><small>คุณต้องการเช็คอินเพื่อเข้าใช้ห้องประชุมใช่หรือไม่</small></p>
                                    <div className="text-center mt-3 mb-2">
                                        <div className="row">
                                            <div className="col-6 p-1">
                                                <span onClick={this.closeModal} className="btn btn-sm mb-0 btn-outline-white-2">Back</span>
                                            </div>
                                            <div className="col-6 p-1">
                                                <input type="button" className="btn btn-sm mb-0 btn-register-2" onClick={this.onSubmitFormConfirm} defaultValue="Check-in" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                {/* Custom Pop up2 */}
                                <div id="popup2" className={this.state.ModelCss02}>
                                    <Link to="#" onClick={this.closeModal2} className="close-popup">×</Link>
                                    <h5 className="text-center mt-3 mb-0 text-danger fontsodo-bold">Are you sure?</h5>
                                    <p className="text-center fontkanit"><small>คุณต้องการยกเลิกการจองห้องประชุมใช่หรือไม่</small></p>
                                    <p className="text-center fontkanit text-danger mt-3 mb-0 fw-bold"><small>กรุณาระบุเหตุผลในการยกเลิก</small></p>
                                    <select className="form-control-select-popup mt-1 fontkanit" name="bookingcancelremark" defaultValue={this.state.bookingcancelremark} onChange={this.onInputchange}>
                                        <option value="ไม่สามารถมาตามเวลาที่จองได้">ลูกค้าไม่มาแสดงตัวภายในเวลาที่กำหนด</option>
                                        <option value="ห้องประชุมไม่พร้อมให้บริการ">ห้องประชุมไม่พร้อมให้บริการ</option>
                                        <option value="ร้านปิดบริการชั่วคราว">ร้านปิดบริการชั่วคราว</option>
                                        <option value="อื่นๆ">อื่นๆ</option>
                                    </select>
                                    <div className="text-center mt-3 mb-2">
                                        <div className="row">
                                            <div className="col-6 p-1">
                                                <span onClick={this.closeModal2} className="btn btn-sm mb-0 btn-outline-white-2">No</span>
                                            </div>
                                            <div className="col-6 p-1">
                                                <input type="button" className="btn btn-sm mb-0 btn-register-cancelled" onClick={this.onSubmitFormCancelConfirm} defaultValue="Yes" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>
        );
    }
}
