import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const liff = window.liff;

export class StoresettingTempclose extends Component {
    static displayName = StoresettingTempclose.name;

    constructor(props) {
        super(props);

        this.state = {
            roomid: "0",
            roomlist: [],
            tempcloseroomlist: []
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    onInputchange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    async onSubmitForm() {
        //Godd to go

        this.setState({
            jpage: 'start'
        });

        //retrive temp-close room list - start
        const requestOptions3 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    UserId: this.state.userLineID,
                    RoomID: this.state.roomid
                }
            )
        };
        await fetch('/api/partnertempcloseroomlist', requestOptions3)
            .then(response => response.json())
            .then(json => {
                this.setState({
                    tempcloseroomlist: json
                });
            });
        //retrive temp-close room list - end

        this.setState({
            jpage: 'done'
        });
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "U3189d053eaa99d5015d7cc4a4cd14f87";
            //let LineuserPicture = "";
        } else {
            liff.init({
                liffId: '1661485377-z2p58Y1Z'
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start

                    //check Partners exists or not - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/partnercheck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "Approve") {
                                //Partners exists and already approve-> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Partners not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Partners exists or not - end

                    //retrive room list - start
                    const requestOptions2 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };
                    await fetch('/api/partnerroomlist', requestOptions2)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                roomlist: json
                            });
                        });
                    //retrive room list - end

                    //retrive temp-close room list - start
                    const requestOptions3 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId,
                                RoomID: this.state.roomid
                            }
                        )
                    };
                    await fetch('/api/partnertempcloseroomlist', requestOptions3)
                        .then(response => response.json())
                        .then(json => {
                            this.setState({
                                tempcloseroomlist: json
                            });
                        });
                    //retrive temp-close room list - end

                    this.setState({
                        jpage: 'done'
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        if (this.state.jpage === "done") {
            var storename = "";
            var idroom = this.state.roomid;
            if (idroom === "0") {
                storename = "ALL";
            }
            else {
                var selectroom = this.state.roomlist.filter(function (item) { return (item.roomId === idroom); });
                storename = selectroom[0].storeName + '-' + selectroom[0].roomName;
            }

            //Dropdown room list - start
            var roomlist = [];
            var thisroom = "";
            for (let i = 0; i < this.state.roomlist.length; i++) {
                thisroom = this.state.roomlist[i].roomId;
                if (thisroom !== idroom) {
                    roomlist.push(
                        <option key={i} value={this.state.roomlist[i].roomId}>[{this.state.roomlist[i].storeName}]-{this.state.roomlist[i].roomName}</option>
                    )
                }
                else {
                    roomlist.push(
                        <option key={i} value={this.state.roomlist[i].roomId}>[{this.state.roomlist[i].storeName}]-{this.state.roomlist[i].roomName}</option>
                    )
                }
            }
            //Dropdown room list - end

            //Temp close room list - start
            var tempcloseroomlist = [];
            for (let i = 0; i < this.state.tempcloseroomlist.length; i++) {
                tempcloseroomlist.push(
                    <div className="col-12 mb-2" key={i}>
                        <div className="card card-room-list">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12"><div className="head-room-name">
                                        {this.state.tempcloseroomlist[i].storeName} - {this.state.tempcloseroomlist[i].roomName}
                                    </div></div>
                                </div>
                                <div className="row row-room-detail">
                                    <div className="col-8 p-0 ps-2">
                                        <div className="mt-2"><span className="room-text-detail"><img src={require("./media/Icon-feather-calendar.png")} alt="Date" className="ms-1" /> {this.state.tempcloseroomlist[i].susDateTxt}</span><br />
                                            <img src={require("./media/Icon-feather-clock.png")} alt="Time" className="ms-1" /> <span className="room-text-detail">{this.state.tempcloseroomlist[i].susDateHrStartTxt} - {this.state.tempcloseroomlist[i].susDateHrEndTxt}</span></div>
                                    </div>
                                    <div className="col-4 text-end align-self-end">
                                        <Link className="btn btn-sm mt-2 mb-0 btn-register-cancelled " to={{
                                            pathname: "/StoresettingTempcloseRemove",
                                            state: {
                                                suspendId: this.state.tempcloseroomlist[i].suspendedId,
                                                storeName: this.state.tempcloseroomlist[i].storeName,
                                                roomName: this.state.tempcloseroomlist[i].roomName,
                                                susDateTxt: this.state.tempcloseroomlist[i].susDateTxt,
                                                susDateHrStartTxt: this.state.tempcloseroomlist[i].susDateHrStartTxt,
                                                susDateHrEndTxt: this.state.tempcloseroomlist[i].susDateHrEndTxt
                                            }
                                        }}>
                                            Remove</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            //Temp close room list - end
        }
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/stb_partner.png")} alt="SBUX Partner Portal" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-4">
                                <h2 className="h4 text-center pt-0 mb-0 fontsodo-bold">Temporary Store Closure</h2>
                                <div className="row p-3 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Store</p></div>
                                    <div className="col-8">
                                        <select className="form-control-select-popup" required name="roomid" value={this.state.roomid} onChange={this.onInputchange}>
                                            {roomlist}
                                        </select>
                                    </div>
                                    <div className="col-2">
                                        <input type="button" className="btn btn-sm mt-0 mb-0 btn-register-2" style={{ padding: '1px 5px' }} onClick={this.onSubmitForm} defaultValue="Apply" />
                                    </div>
                                </div>
                                <div className="row mt-3 p-2 pt-0 pe-0 row-card-room-list-2">
                                    <div className="col-12 mb-2">
                                        <div className="card card-room-list">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="head-room-name lh-sm">{storename}</div>
                                                </div>
                                            </div>                                            
                                        </div>                                        
                                    </div>
                                    {tempcloseroomlist}
                                </div>
                            </div>
                            <div className="box-footer-button position-relative mt-2">
                                <div className="row text-center mt-3 mb-0 justify-content-center">
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <Link to="/" className="btn btn-lg mb-0 btn-register-3">Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <Link to="/StoresettingTempcloseAdd" className="btn btn-lg mt-0 mb-0 btn-register-2"><span className="icon-plus">+</span>Add more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>
        );
    }
}
