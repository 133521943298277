import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from './components/Layout';

import { Home } from './components/Home';
import { HomeWait } from './components/HomeWait';
import { HomeNotFound } from './components/HomeNotFound';
import { HomeNotAllow } from './components/HomeNotAllow';

import { NotInLine } from './components/NotInLine';
import { Mistake } from './components/Mistake';
import { MistakeLine } from './components/MistakeLine';

import { Term } from './components/Term';

import { Dashboard } from './components/Dashboard';

import { Booking } from './components/Booking';
import { BookingUpcoming } from './components/BookingUpcoming';
import { BookingPast } from './components/BookingPast';
import { BookingDetail } from './components/BookingDetail';
import { BookingCancel } from './components/BookingCancel';
import { BookingCheckIn } from './components/BookingCheckIn';

import { Storesetting } from './components/Storesetting';
import { StoresettingMaxHour } from './components/StoresettingMaxHour';

import { StoresettingTempclose } from './components/StoresettingTempclose';
import { StoresettingTempcloseAdd } from './components/StoresettingTempcloseAdd';
import { StoresettingTempcloseAddErr02 } from './components/StoresettingTempcloseAddErr02';
import { StoresettingTempcloseRemove } from './components/StoresettingTempcloseRemove';
import { StoresettingTempcloseAddConfirm } from './components/StoresettingTempcloseAddConfirm';

import './components/style/custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/homewait' component={HomeWait} />
            <Route path='/homenotfound' component={HomeNotFound} />
            <Route path='/homenotallow' component={HomeNotAllow} />

            <Route path='/notinline' component={NotInLine} />
            <Route path='/mistake' component={Mistake} />
            <Route path='/mistakeline' component={MistakeLine} />

            <Route path='/term' component={Term} />

            <Route path='/dashboard' component={Dashboard} />

            <Route path='/booking' component={Booking} />
            <Route path='/bookingupcoming' component={BookingUpcoming} />
            <Route path='/bookingpast' component={BookingPast} />
            <Route path='/bookingdetail' component={BookingDetail} />
            <Route path='/bookingcancel' component={BookingCancel} />
            <Route path='/bookingcheckin' component={BookingCheckIn} />

            <Route path='/storesetting' component={Storesetting} />
            <Route path='/storesettingmaxhour' component={StoresettingMaxHour} />

            <Route path='/storesettingtempclose' component={StoresettingTempclose} />
            <Route path='/storesettingtempcloseadd' component={StoresettingTempcloseAdd} />            
            <Route path='/storesettingtempcloseadderr02' component={StoresettingTempcloseAddErr02} />
            <Route path='/storesettingtempcloseremove' component={StoresettingTempcloseRemove} />      
            <Route path='/storesettingtempcloseaddconfirm' component={StoresettingTempcloseAddConfirm} />      
            
            
      </Layout>
    );
  }
}
