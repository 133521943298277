import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const liff = window.liff;

export class Storesetting extends Component {
    static displayName = Storesetting.name;

    constructor(props) {
        super(props);

        this.state = {
            coupon1css: "text-coupon-num d-none"
        };
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
            //let LineuserPicture = "";
        } else {
            liff.init({
                liffId: '1661485377-z2p58Y1Z'
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Partners exists or not
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/partnercheck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "Approve") {
                                //Partners exists and already approve-> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Partners not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/stb_partner.png")} alt="SBUX Partner Portal" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-5">
                                <form id="myform">
                                    <fieldset className="fieldset-form pt-1">
                                        <div className="row text-center mt-3 mb-0 justify-content-center">
                                            <div className="col-10 p-0">
                                                <div className="d-grid">
                                                    <Link to="/Booking" className="btn btn-lg mt-2 mb-0 btn-register-3 lh-sm"><span className="fs-5">Booking Status<br />
                                                        <small className="fontkanit fs-6">รายการการจองห้องประชุม</small></span></Link>
                                                </div>
                                            </div>
                                            <div className="col-10 p-0">
                                                <div className="d-grid">
                                                    <Link to="/StoresettingTempclose" className="btn btn-lg mt-2 mb-0 btn-register-2 lh-sm"><span className="fs-5">Temporary Store Closure<br />
                                                        <small className="fontkanit fs-6">ตั้งค่าการปิดร้านชั่วคราว</small></span></Link>
                                                </div>
                                            </div>
                                            <div className="col-10 p-0">
                                                <div className="d-grid">
                                                    <Link to="/StoresettingMaxHour" className="btn btn-lg mt-4 mb-0 btn-register-3 lh-sm"><span className="fs-5">Max Hour per Booking<br />
                                                        <small className="fontkanit fs-6">กำหนดระยะเวลาสูงสุดต่อการจอง</small></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>
        );
    }
}
