import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//liffId: '1661485377-z2p58Y1Z'

const liff = window.liff;

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none",
            ModelCssinner03: "d-none",
            Pdpa: "No",
            ModelCssButton: "btn btn-lg mt-2 mb-0 btn-register-2",
            storecode: "",
            storetel: "",
            ans01: "",
            userLineID: ""
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none",
            ModelCssinner03: "d-none",
        });
    }

    onInputchange(event) {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;        

        if (name === "storecode") {            
            if (value.length > 5) {
                value = value.substring(0, 5)                
                this.setState({
                    [name]: value
                });
            } else {
                this.setState({
                    [name]: value
                });
            }
        }
        else if (name === "storetel") {
            if (value.length > 10) {
                value = value.substring(0, 10)

                this.setState({
                    [name]: value
                });
            } else {
                this.setState({
                    [name]: value
                });
            }
        }
        else {
            this.setState({
                [name]: value
            });
        }
    }

    async onSubmitForm() {
        let isthisvalid = "yes";

        if (!this.state.storecode) {
            this.setState({
                ModelCssinner01: ""
            });

            isthisvalid = "no";
        }
        if (!this.state.storetel) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        } else if (this.state.storetel.length !== 10) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        }        

        if (isthisvalid === "no") {
            this.setState({
                ModelCss01: "popup"
            });
        }
        else {
            //Godd to go
            //disable submit button
            this.setState({
                ModelCssButton: "btn btn-lg mb-0 btn-register-2-dis"
            });

            // POST request using fetch inside useEffect React hook                    
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        UserId: this.state.userLineID,
                        StoreCode: this.state.storecode,
                        StoreTel: this.state.storetel
                    }
                )
            };

            const response = await fetch('/api/partnerregister', requestOptions);
            const data = await response.json();

            if (data.responsecode === "Approve") {
                //Partners exists and already approve
                this.props.history.push('/Dashboard')
            }
            else if (data.responsecode === "Wait") {
                this.props.history.push('/HomeWait')
            }
            else if (data.responsecode === "StoreNotFound") {
                this.props.history.push('/HomeNotFound')
            }
            else if (data.responsecode === "StoreHasPartner") {
                this.props.history.push('/HomeNotAllow')
            }
            else {
                this.props.history.push({
                    pathname: "/Mistake",
                    state: {
                        ErrorTxt: data.responsemessage
                    }
                });
            }
        }
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine')

            //let LineuserId = "U3189d053eaa99d5015d7cc4a4cd14f87";
            //let LineuserPicture = "";                          
        } else {
            liff.init({
                liffId: '1661485377-z2p58Y1Z'
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check Partners exists or not
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/partnercheck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "Approve") {
                                //Partners exists and already approve
                                this.props.history.push({
                                    pathname: "/Dashboard",
                                    state: {
                                        storeName: json.storeName,
                                        storeStatus: json.storeStatus,
                                        room1Status: json.room1Status,
                                        room2Status: json.room2Status
                                    }
                                });
                            }
                            else if (json.responsecode === "Wait") {
                                this.props.history.push('/HomeWait')
                            }
                            else {
                                //Partners not exists -> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });
                            }
                        });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/stb_partner.png")} alt="SBUX Partner Portal" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-5">
                                <form id="myform">
                                    <h2 className="h4 text-center pt-3 mb-0 fontsodo-bold">Register</h2>
                                    <h3 className="h5 text-center mb-3 fontsodo-semibold">Please fill your information</h3>
                                    <fieldset className="fieldset-form pt-1">
                                        <div className="form-group">
                                            <input type="tel" className="form-control" placeholder="Store ID (Ex.14001)" required name="storecode" id="storecode" value={this.state.storecode} onChange={this.onInputchange} />
                                        </div>
                                        <div className="form-group">
                                            <input type="tel" className="form-control" placeholder="Store Tel." required name="storetel" id="storetel" value={this.state.storetel} onChange={this.onInputchange} />
                                        </div>
                                        <div className="row text-center mt-3 mb-0 justify-content-center">
                                            <div className="col-6 p-0">
                                                <div className="d-grid">
                                                    <input type="button" className={this.state.ModelCssButton} onClick={this.onSubmitForm} defaultValue="Register" />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            {/* Custom Pop up */}
                                            <div id="popup1" className={this.state.ModelCss01}>
                                                <Link to="#" onClick={this.closeModal} className="close-popup">×</Link>
                                                <div className="text-center">
                                                    <strong>กรุณากรอกข้อมูลให้ถูกต้อง</strong><br />
                                                    <strong>และครบถ้วน</strong>
                                                </div>
                                                <ul className="text-start">
                                                    <li className={this.state.ModelCssinner01}>Store ID</li>
                                                    <li className={this.state.ModelCssinner02}>Store Tel</li>
                                                </ul>
                                                <div className="text-center mt-3 mb-2">
                                                    <div onClick={this.closeModal} className="btn btn-lg mb-0 btn-register-2">ตกลง</div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>

        );
    }
}
