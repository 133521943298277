import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const liff = window.liff;

export class StoresettingTempcloseRemove extends Component {
    static displayName = StoresettingTempcloseRemove.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCssButton: "btn btn-lg mb-0 btn-register-cancelled",
            suspendId: this.props.location.state.suspendId,
            storeName: this.props.location.state.storeName,
            roomName: this.props.location.state.roomName,
            susDateTxt: this.props.location.state.susDateTxt,
            susDateHrStartTxt: this.props.location.state.susDateHrStartTxt,
            susDateHrEndTxt: this.props.location.state.susDateHrEndTxt
        };

        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    async onSubmitForm() {
        //Godd to go

        // POST request using fetch inside useEffect React hook                    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    SuspendedId: this.state.suspendId
                }
            )
        };

        const response = await fetch('/api/partnertempcloseroomremove', requestOptions);
        const data = await response.json();

        if (data.responsecode === "OK") {
            //Remove success
            this.props.history.push('/storesettingtempclose')
        }
        else {
            this.props.history.push({
                pathname: "/Mistake",
                state: {
                    ErrorTxt: data.responsemessage
                }
            });
        }
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
            //let LineuserPicture = "";            
        } else {
            liff.init({
                liffId: '1661485377-z2p58Y1Z'
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start

                    //check Partners exists or not - start
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/partnercheck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "Approve") {
                                //Partners exists and already approve-> Show This page
                                this.setState({
                                    userLineID: LineuserId
                                });

                            }
                            else {
                                //Partners not exists or not approve -> Show Home page
                                this.props.history.push('/')
                            }
                        });
                    //check Partners exists or not - end


                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home position-relative">
                <div className="head-nav p-0">
                    <img src={require("./media/stb_partner.png")} alt="SBUX Partner Portal" className="img-fluid mt-0 mb-0" />
                </div>
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="form-enter pt-0 mt-4">
                                <h2 className="h4 text-center pt-0 mb-0 fontsodo-bold">Temporary Store Closure</h2>
                                <div className="row p-3 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Store</p></div>
                                    <div className="col-10"><p className="fontsodo-semibold text-green mb-0">{this.state.storeName}</p></div>
                                </div>
                                <div className="row p-3 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Room</p></div>
                                    <div className="col-10"><p className="fontsodo-semibold text-green mb-0">{this.state.roomName}</p></div>
                                </div>
                                <div className="row p-3 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Date</p></div>
                                    <div className="col-10"><p className="fontsodo-semibold mb-0"><img src={require("./media/Icon-feather-calendar.png")} alt="Date" className="ms-0 me-2" /> {this.state.susDateTxt}</p></div>
                                </div>
                                <div className="row p-3 pb-0">
                                    <div className="col-2"><p className="fontsodo-semibold mb-0">Time</p></div>
                                    <div className="col-10"><p className="fontsodo-semibold mb-0"><img src={require("./media/Icon-feather-clock.png")} alt="Time" className="ms-0 me-2" /> {this.state.susDateHrStartTxt} - {this.state.susDateHrEndTxt}</p></div>
                                </div>
                            </div>
                            <div className="box-footer-button position-relative">
                                <div className="row text-center mt-3 mb-0 justify-content-center">
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <Link to="/StoresettingTempclose" className="btn btn-lg mb-0 btn-register-3">Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-grid">
                                            <input type="button" className={this.state.ModelCssButton} onClick={this.onSubmitForm} defaultValue="Remove" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-1 pb-0">
                                <form>
                                    <div className="text-center" style={{ height: '160px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                        </>
                }
            </div>
        );
    }
}